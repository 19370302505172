.mb {
    margin-bottom: .75rem;
}

@media only screen and (max-width: 767px) {
    .mobile-hide {
      display: none !important;
    }
    .hidden.mobile-only {
        display: flex !important;
    }
}

@media only screen and (min-width: 768px) {
    .hidden.mobile-only {
        display: none !important;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .medium-hide {
        display: none !important;
    }
}

.no-image-selected {
    margin-bottom: .5em !important;
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ui.red.link {
    color: #db2828!important;
}

.ui.red.link:hover {
    color: #d01919!important;
    text-decoration: underline;
}

.ui.form .field.error .button {
    color: #db2828 !important;
    box-shadow: 0 0 0 1px #db2828  inset!important;
    background: #fff6f6 !important;
}

.ui.form .field.error .no-image-selected {
    color: #db2828 !important;
}

.ui.form .field.error .no-image-selected i.icon {
    color: #db2828 !important;
}

.ui.card>.content>.header {
    display: inline;
}

.ui.cards>.card>.image {
    object-fit: contain;
}

.ui.basic.red.basic.loading.button:not(.inverted):after {
    /* Was gray by default */
    border-top-color: #e25253;
}

.ui.divider.fitted {
    margin: .5rem 0 !important;
}

.downloads-list .ui.buttons {
    margin-bottom: 0.35rem;
    margin-right: 0.35rem;
}

.ui.message.info .ui.loader:after, .ui.dimmer .ui.loader.blue:after, .ui.loader.blue:after {
    border-color: #2185d0 transparent transparent !important;
}

.ui.dimmer .ui.loader.blue:before {
    border: .2em solid rgba(0,0,0,.1);
}

label.ui.gray.text, span.ui.grey.text {
    color: #767676;
}

.ui.slow.loader:after, .ui.slow.loader.loading:after {
    animation-duration: .9s;
}

.ui.mini.link {
    font-size: .78571429rem;
}

.s-alert-box {
    border-radius: .2em;
}

.ui.header.fitted.small {
    margin-top: 1em;
}
.ui.label > .detail {
    margin-left: .6em !important;
}
@media only screen and (max-width: 767px) {
    .ui.grid.stackable > .eight.wide.column {
        padding-left: 0 !important;
        padding-right: 0 !important;
        /* padding-bottom: 0 !important; */
    }
}

.small.red.link {
    color: #db2828 !important;
    font-size: .8em;
    font-weight: normal;
    vertical-align: bottom;
    cursor: pointer;
}

.small.red.link:hover {
    color: #d01919 !important
}

.ui.cards ~ .ui.cards {
    margin: -.875em -.5em !important;
}

.ui.image.checkered {
    background-image: linear-gradient(45deg, #fafafa 25%, transparent 25%), linear-gradient(135deg, #fafafa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #fafafa 75%), linear-gradient(135deg, transparent 75%,
    #fafafa 75%) !important;
    background-size: 25px 25px !important;
    background-position: 0 0, 12.5px 0, 12.5px -12.5px, 0px 12.5px !important;
}

.s-alert-box {
    /** Above modal dimmer */
    z-index: 1001 !important;
}