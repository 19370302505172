

/* password input with hide/show value */
.password-input-container {
  position: relative;
}

.password-input-container  .password-strength {
    position: absolute;
    width: 0%;
    height: 4px;
    bottom: -8px;
    left: 0;
    border-radius: 4px;
    background: transparent;
    transition: all 300ms ease-in-out;
}

.password-strength[data-score="null"]{
  width: 0;
  background: red;
}

.password-strength[data-score="0"]{
  width: 5%;
  background: #F44336;
}
.password-strength[data-score="1"]{
  width: 25%;
  background: #F44336;
}
.password-strength[data-score="2"]{
  width: 50%;
  background: #FFEB3B;
}
.password-strength[data-score="3"]{
  width: 75%;
  background: #4CAF50;
}
.password-strength[data-score="4"]{
  width: 100%;
  background: #4CAF50;
}
.error.label {
  border-color: #e0b4b4 !important;
  color: #9f3a38 !important;
}