/** Centered icon */
.ui.item.dropdown > i.icon {
    margin: 0 !important;
}

@media only screen and (max-width: 991px) {
    .ui.menu .item.mobile-hide > i.icon {
        margin: 0;
    }
}
@media only screen and (max-width: 767px) {
    .ui.floating.dropdown > .menu {
        margin-right: .5em !important;
    }
}