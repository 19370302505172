
.TimeField.ui.input {
    margin-left: .5em;
    width: calc(40% - 0.5em) !important;
}

.TimeField.ui.input.mini {
    width: 7rem !important;
}


.DayPickerInput {
    width: 60%;
}
@media only screen and (max-width: 767px) {
    .DayPickerInput {
        width: 100%;
    }
    .TimeField.ui.input {
        width: 100% !important;
        margin-top: .75em;
        margin-left: 0;
    }
}

.DayPickerInput-Overlay {
    z-index: 5 !important;
    border-radius: .2em; 
    margin-top: .2em;
    border: 1px solid rgba(34,36,38,.15);
    box-shadow: 0 2px 4px 0 rgba(34,36,38,.12),0 2px 10px 0 rgba(34,36,38,.15) !important;
}

/**
 * Make selected day selector round. (semantic-ui overrides this)
 */
.DayPicker > * {
    line-height: 1.15;
}

.TimeField.has-end-time:after {
    content: "";
}
@media only screen and (min-width: 768px) {
    .TimeField.has-end-time:after {
        content: "—";
        position: absolute;
        top: 7px;
        right: -19px;
    }
}