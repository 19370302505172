.leaflet-container {
    border-radius: .28571429rem;
}

/*
 *Below top menu
 */
.leaflet-pane {
    z-index: 50;
}
.leaflet-control {
    z-index: 51;
}
.leaflet-top, .leaflet-bottom {
    z-index: 52;
}

/* z-index above Leaflet tiles.
 */
.ui.reset-location.button {
    z-index: 555;
    position: absolute;
    margin: 1em;
    bottom: 0;
}