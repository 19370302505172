/*
	MINIMAL DESIGN
	based on the sleek design of Jacob SÃ¤wensten ( http://www.jacobsawensten.com/ )
	PDF: https://dribbble.com/shots/1460141-Free-minimal-browser-window/attachments/216671
*/
.facade-minimal
{
	position:  relative;
	/* margin:  24px 0;
	padding:  64px 16px 10px 16px; */
	box-shadow:  0px 5px 10px rgba(0,0,0,0.1);
	font-family: sans-serif;
	color:#454545;
	border-radius: 3px;
	overflow-x:hidden;
}

.facade-minimal > iframe {
  margin-top: 41px;
  margin-bottom: -5px;
  border-radius:  0 0 3px 3px;
  background: #000;
}

.facade-minimal:before
{
	content:  "";
	position:  absolute;
	display:  block;
	top:  -1px;
	left:  -1px;
	right:  -1px;
	height:  42px;
	border-bottom:  1px solid rgba(0,0,0,0.05);
	border-radius:  3px 3px 0px 0px;	background: url('data:image/svg+xml;charset=utf-8,<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="minimal-browser-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><g id="Ellipse_1"><g><circle fill-rule="evenodd" clip-rule="evenodd" fill="%23f44" cx="8" cy="8" r="5"/></g></g></svg>') 16px 14px no-repeat,
			url('data:image/svg+xml;charset=utf-8,<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="minimal-browser-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><g id="Ellipse_1"><g><circle fill-rule="evenodd" clip-rule="evenodd" fill="%23fb5" cx="8" cy="8" r="5"/></g></g></svg>') 32px 14px no-repeat,  
			url('data:image/svg+xml;charset=utf-8,<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="minimal-browser-button" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><g id="Ellipse_1"><g><circle fill-rule="evenodd" clip-rule="evenodd" fill="%239b3" cx="8" cy="8" r="5"/></g></g></svg>') 48px 14px no-repeat,  
			/* url('data:image/svg+xml;charset=utf-8,<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg version="1.1" id="minimal-browser-settings" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><g id="Menu_icon"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="%23B3B3B3" d="M1,1v3h14V1H1z M1,10h14V7H1V10z M1,16h14v-3H1V16z"/></g></g></svg>')  right 16px top 14px no-repeat, */
			#e8e8e8;
}

.facade-minimal:after
{
	content:  attr(data-url);
	position:  absolute;
	padding:  2px 16px 0px 32px;
	top: 9px;
	height:  24px;
	left:  80px;
	right:  16px;
	border-radius:  2px;
	box-shadow:  inset 0 1px 1px -1px rgba(0,0,0,0.5);
	font-size:  12px;
	line-height:  24px;
	white-space:  nowrap;
	overflow:  hidden;
	text-overflow:  ellipsis;
	/* direction:  rtl; */
  text-align:  left;
  background: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Refresh_icon_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="11px" height="11px" viewBox="0 0 20 25" enable-background="new 0 0 16 16" xml:space="preserve"><path fill-rule="evenodd" clip-rule="evenodd" fill="%23B3B3B3" d="M 12 0 C 8.699219 0 6 2.699219 6 6 L 6 11 L 3 11 L 3 24 L 21 24 L 21 11 L 18 11 L 18 6 C 18 2.699219 15.300781 0 12 0 Z M 12 2 C 14.21875 2 16 3.78125 16 6 L 16 11 L 8 11 L 8 6 C 8 3.78125 9.78125 2 12 2 Z"></path></svg>') 10px 8px no-repeat, #FFF;
	/* background: url('data:image/svg+xml;charset=utf-8,<svg version="1.1" id="Refresh_icon_1_" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve"><g id="minimal-browser-refresh"><g><path fill-rule="evenodd" clip-rule="evenodd" fill="%23B3B3B3" d="M12.5,10.9l2.5-3h-1.7C13.2,4.6,10.7,2,7.7,2C4.5,2,2,4.7,2,8c0,3.3,2.5,6,5.7,6c1.3,0,2.5-0.5,3.5-1.3l-1-1.1c-0.7,0.5-1.6,0.9-2.5,0.9c-2.3,0-4.2-2-4.2-4.5s1.9-4.5,4.2-4.5c2.3,0,4.2,1.9,4.2,4.4h-2L12.5,10.9z"/></g></g></svg>') 8px 6px no-repeat,
				#FFFFFF; */
}